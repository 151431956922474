.policy--tree-header {
  margin: 0.5em;
}

.policy--tree {
  padding-bottom: 0px;
  padding-right: 1rem;
  border-right: 1px solid #D5DBDB;
  height: 100%;
  max-height: 80vh;
}

.policy--tree-item-text:hover {
  cursor: pointer;
}

.policy--tree-list {
  max-height: 70vh;
  overflow: hidden;

  &:hover {
    overflow: auto;
  }

  #policy--no-folders-available {
    margin: 0.5em !important;
    opacity: 0.5;
  }
}