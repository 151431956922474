@import 'src/indexStyles';

.awsui>awsui-spinner {
  position: absolute;
  top: 25vh;
  left: 50vw;

  >.awsui-spinner.awsui-spinner-component.awsui-spinner-size-large.awsui-spinner-variant-normal {
    height: 100px;
    width: 100px;
  }
}

.policy--full-screen-layout {
  padding: 0rem 5rem;
}

// This can be removed once the classic design is removed.
.policy--polaris-table {
  th:first-of-type {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  td:first-of-type {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}