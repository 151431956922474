@import '/src/indexStyles.scss';

.policy--dashboard-toplevel-layout {
  margin: 1rem 0px 0px 0px;
  height: 85vh;

  div[class*='awsui_content_'] {
    height: 100%;

    div[class*='awsui_grid_'] {
      height: 100%;
    }
  }
}

.policy--dashboard-box-layout {
  height: 100%;
  max-height: 80vh;

  #policy--folder-tree-section {
    height: 98%;
  }
}

.policy-folder-tree-view-header {
  color: $policy--general-font-color;
  margin: 0px;
}

.policy--dashboard-tree-color {
  color: gray;
  font-size: 0.80rem !important;
  font-family: $policy--font-family !important;
}

.justify-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

#favorites-section {
  #favorites-icon-container {
    display: flex;
    align-items: center;
    height: 100%;

    #favorites-icon {
      width: 1rem;
    }
  }

  #favorites-title {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .favorited {
    color: #FF9900;
  }

  .not-favorited {
    color: grey;
  }
}

.hide {
  opacity: 0;
}