@import './src/indexStyles';

.policy--toast-alert {
  position: fixed;
  width: 30em;
  top: 20px;
  right: 12px;
  transition: top 300ms;
  z-index: 9999;

  div {
    text-align: left !important;
  }
}

.policy--toast-alert-isClosed {
  top: -20em;
}