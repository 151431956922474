@use '@amzn/awsui-design-tokens/polaris.scss' as awsui;
@import '/src/indexStyles.scss';

.policy--document-none {
  height: 100%;
  color: $policy--general-font-color;
  padding: 1rem;

  * {
    font-size: 0.75rem;
  }
}

.policy--document-none-message {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 0.9rem;

  >#folder-icon {
    margin-left: 30px;
    margin-right: 30px;
  }
}

.policy--document-none-message-next {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 0.75rem;
}

.policy--folder-tree-none-message {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0rem 2rem;

  >#folder-icon {
    margin-left: 30px;
    margin-right: 30px;
  }
}

.policy--document-title-margin {
  display: flex;
  align-items: center;

  &>div {
    padding: 2px !important;
  }
}

.policy--document-row-layout {
  background-color: white;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  border-radius: 5px;
  border: 1px dotted lightsteelblue !important;
}

.policy--document-item-layout {
  padding: 5px 8px;
}

.policy--document-checkbox {
  cursor: pointer !important;
}

.align-center {
  display: flex;
  align-items: center;
  height: 100%;
}

/* Document name in the list view */
.policy--document-detail-title {
  color: #107896 !important;
}

.policy--folder-search {
  margin-left: 0.5rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.policy--folder-search-button {
  text-wrap: nowrap;
}

.policy--folder-search-filters {
  margin-left: 0.5rem;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}

.policy--document-attributes {
  color: awsui.$color-text-body-secondary !important;
}

.policy--center-flex {
  display: flex;
  justify-content: center;
}

.policy--document-link-icon {
  margin-top: 5px;
  cursor: pointer;
  margin-right: 3px;

  svg {
    opacity: 0.6;
    width: 0.5rem;

    :hover {
      opacity: 1;
    }
  }
}

.policy--document-icon {
  color: #6e6e6e !important;
}

.policy--document-icon-warning {
  color: #ff9900 !important;
}

.policy--box-header {
  font-size: 1.25rem;
  color: #107896 !important;
  display: flex;
  align-items: center;
}

.policy--box-header-border {
  padding: 0px 0.5rem;

  &>div {
    width: 100%;
  }
}

#policy--box-header-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#policy--document-pagination-documents-section {
  margin-bottom: 1rem;
  overflow: hidden;
  max-height: 65vh;

  &:hover {
    overflow: auto;
  }
}

.expandable-section-styles {
  div[class*="awsui_header-deprecated_"] {
    display: flex;
    align-items: center;
  }

  .expandable-section-styles-title {
    margin: 0px;
  }
}

.link-container {
  display: flex;
  align-items: center;
}

.link-with-divider {
  // content: " | ";
  // white-space: pre;
  padding: 0rem 1rem 0rem 0rem;
  border-right: 1px solid grey;
}

.link-with-divider:last-child::after {
  content: "";
}