#icon-wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;

  #star-icon {
    color: #FF9900;

    &:hover {
      color: #f59403;
    }
  }

  #star-border-icon {
    color: rgb(178, 178, 178);

    &:hover {
      color: rgb(151, 151, 151);
    }
  }

  * {
    width: 1.3rem;
  }
}