$policy--topnav-height: 40px;
$policy--general-font-color: dimgray;
$policy--title-font-color: #006bb3;
$policy--font-family: "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;

body {
  overflow-x: hidden;
}

.policy--spinner {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 50%;
}

.policy--spinner-section {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 20%;
}

a {
  text-decoration: none !important;
}

.policy--capitalize {
  text-transform: capitalize !important;
}

.policy--general-h1 {
  margin: 0rem !important;
  font-family: "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-style: normal !important;
  color: #232E40 !important;
  font-size: 20px !important;
}

.policy--general-h3 {
  margin: 0rem !important;
  font-family: "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-style: normal !important;
  color: #232E40 !important;
  font-size: 16px !important;
}

.policy--general-float-right {
  float: right !important;
}

.policy--dashboard-popover {
  color: #FF9900 !important;

  .policy--dashboard-popover-description {
    color: #808080 !important;
    font-size: 12px !important;
  }
}

.policy--dashboard-popover-link {
  color: #006bb3 !important;
  font-size: 12px !important;
}

.policy--tool-tip-description {
  font-family: "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  color: #808080 !important;
  font-size: 12px !important;
}

.policy--tool-tip-header {
  font-family: "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  color: #FF9900 !important;
  font-size: 12px !important;
}

.policy--2-logo {
  font-family: "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-size: 26px !important;
}

sub {
  vertical-align: sub;
  color: #363636;
  font-family: "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
}

.policy--loading-h1 {
  margin: 0rem !important;
  font-family: "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-style: normal !important;

  color: #575757 !important;
  font-size: 18px !important;
}

.policy--error-h1 {
  margin: 0rem !important;
  font-family: "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-style: normal !important;
  color: #E06666 !important;
  font-size: 22px !important;
}

.policy--error-h3 {
  margin: 0rem !important;
  font-family: "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-style: normal !important;
  color: darkgrey !important;
  font-size: 16px !important;
  text-align: center !important;
}

.policy--empty-messages {
  font-family: "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  color: #575757 !important;
  font-size: 13px !important;
  text-align: center;
}

.policy--general-header {
  font-size: 1rem;
  font-family: "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  align-items: center;
  margin: 1em 0 0 0 !important;
  border-bottom: 1px dotted #D5DBDB;
  color: #232E40;
}

.policy--general-header-border {
  font-size: 1rem;
  font-family: "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  align-items: center;
  margin: 1em 0 0 0 !important;
  border: 1px dotted lightsteelblue !important;
  color: #006bb3 !important;
  padding: 5px !important;
  background-color: whitesmoke;
}

.policy--button-hidden {
  display: none !important;

  * {
    display: none !important;
  }
}

.policy--icon-help {
  font-family: "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-style: normal;
  // font-size: 3rem !important;
  color: #107896 !important;
  cursor: pointer;

  :hover {
    color: #E06666;
  }
}

.policy--text-help {
  font-family: "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-style: normal;
  font-size: 1.2rem !important;
  color: #107896 !important;
  cursor: pointer;
  padding: 0.3rem;

  :hover {
    color: #E06666;
  }
}

.policy--folder-dashboard-icon {
  margin-left: 0px !important;
}

.policy--icon-help-marigin {
  margin-top: 3px !important;
}

.policy--box-shadow {
  box-shadow: rgba(0, 28, 36, 0.3) 0px 1px 1px 0px, rgba(0, 28, 36, 0.15) 1px 1px 1px 0px, rgba(0, 28, 36, 0.15) -1px 1px 1px 0px;
}