@use '@amzn/awsui-design-tokens/polaris.scss' as awsui;

// Overide Polaris styles.
#policy--topnav {
  [aria-label='Phone Tool Link'] {
    padding-right: 2.5rem !important;

    span {
      height: 100%;

      img {
        width: 2.5rem !important;
        border-radius: 2px;
      }

      .user-profile-caret {
        display: flex;
        align-items: center;
      }

      span {
        width: initial !important;
      }
    }
  }

  [aria-label='Getting Started'] {
    span {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;

      #policy--getting-started-icon {
        display: flex;
        align-items: center;

        svg {
          width: 2.5rem !important;
          height: 2.5rem !important;
        }
      }
    }
  }


  #policy--feedback-icon {
    height: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 0.3rem;


    svg {
      width: 2.3rem !important;
      height: 2.3rem !important;
    }
  }
}

.policy--topnav-searchbar {
  display: flex;
  align-items: center;

  .policy--topnav-searchbar-input {
    width: 100%;
  }

  .policy--advanced-search-button {
    cursor: pointer;
    margin-left: 0.3rem;
    display: flex;
    align-content: center;

    &:hover {
      color: white;
    }

    svg {
      width: 2.5rem !important;
      height: 2.5rem !important;
    }
  }
}

[aria-label='Polaris Toggle'] {

  span[class*='awsui_icon_'] {
    width: 100% !important;

    #polaris-toggle-section {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      gap: 0.5rem;
      word-break: initial;
      height: 100%;
    }
  }
}

.hot-spot {
  display: flex;
  align-items: center;
  height: 100%;
  padding-top: 0.4rem !important;
}

button[aria-label="Create New Item"] {
  background-color: awsui.$color-background-button-primary-default !important;
  margin: 1.2rem 0px !important;
  height: 3rem !important;
  border-radius: awsui.$border-radius-button !important;
  padding: 0px 0.8rem !important;
  color: awsui.$color-text-button-primary-default !important;

  &:hover {
    background-color: awsui.$color-background-button-primary-hover !important;
  }
}

.add-new {
  display: flex;
}

.polaris-toggle-button {
  span[class*='awsui_label-wrapper_'] {
    align-items: center !important;
    cursor: pointer !important;

    span[class*='awsui_control_'] {
      margin: 0px !important;

      input {
        cursor: pointer !important;
      }
    }

    span[class*='awsui_label_'] {
      color: rgb(178, 178, 178) !important;
    }
  }
}