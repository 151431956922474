.tutorial-document-actions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.padding {
  padding-left: 0.25rem !important;
  padding-right: 1.25rem !important;
}

.policy--field-wrappers-languages {
  button[class*='awsui_dismiss-button'] {
    cursor: default !important;
    span[class*='awsui_icon_'] {
      display: none !important;
    }
  }
}
// Dark mode styles
.darkMode {
  ::-webkit-scrollbar {
    background-color: #0f1b2a;
    height: 0.5rem;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: rgb(120, 120, 120) !important;
  }

  .custom-home__header {
    border-bottom: 1px solid grey;
  }

  .policy--flyover-content,
  .policy--general-header,
  .policy--flyover-footer,
  .policy--document-item-layout {
    background-color: #0f1b2a;
    color: #d1d5db;
  }

  .policy--field-wrapper {
    &:hover {
      background-color: #0f1b2a !important;
    }
  }

  .policy--flyover-portal-cover {
    background-color: grey;
    opacity: 0.5;
  }

  .policy--field-read-only {
    background-color: #0f1b2a !important;
  }

  .policy--empty-messages,
  .policy--folder-header-desc,
  .policy--document-attributes,
  .policy--box-header,
  .policy--search-menu-header,
  .policy--document-detail-title {
    color: #d1d5db !important;
  }
}


.textArea {
  color: red !important;
  width: 40px !important;
}