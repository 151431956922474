.policy--loading-spinner-section {
    align-items: center;
    justify-content: center;

    .policy--loading-spinner-message {
        color: lightslategray !important;
    }

    &.left {
        justify-content: left;
    }

    &.right {
        justify-content: right;
    }


    &.policy--absolute-center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}